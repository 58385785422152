<template>
  <div class="about" v-loading="loading">
    请<a href="login">单击这里</a>，重新登录。
  </div>
</template>
<script>
import { filterUrl } from "@/utils/validate";

export default {
  data() {
    return {
      loading: false,
      url: "",
    };
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$store
        .dispatch("login")
        .then(() => {
          this.$router.push({ path: this.url || "/admin" }).catch(() => {});
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  created() {
    this.url = filterUrl(this.$route.query.redirect);
    this.handleLogin();
  },
};
</script>
